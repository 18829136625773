@import 'styles/mixins.scss';

.root {
  border-radius: 50px;
  min-width: 110px;
  border: none;
  box-shadow: none;

  &:focus {
    border: none;
    box-shadow: none;
    outline: none !important;
  }

  &:global(.btn-tertiary) {
    color: $color-primary--dark;
  }

  &:global(.btn-primary) {
    background-color: $color-primary;
  }

  &:global(.btn-secondary) {
    background-color: $color-secondary;
  }
}
