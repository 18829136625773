@import "styles/variables.scss";

.root {
  height: 100%;
  margin: 0 auto;
  padding-top: 35%;
}

.indicator {
  @media (max-width: $breakpoint-sm) {
    transform: translateY(-150%) scale(5);
  }

  transform: translateY(-150%) scale(10);
}

svg:not(:root) {
  overflow: hidden;
}

.indicator svg polyline {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.back {
  stroke: white;
}

.front {
  stroke: $color-secondary;
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 46;
  animation: dash 1.5s linear infinite;
}

@keyframes dash {
  20% {
    opacity: 0.8;
    stroke: $color-accent;
  }
  50% {
    opacity: 1;
    stroke: $color-tertiary;
  }
  90% {
    opacity: 0.6;
    stroke: $color-secondary;
  }

  to {
    stroke-dashoffset: 2;
  }
}