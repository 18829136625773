@import 'styles/variables.scss';
.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    button {
        border-radius: 32px;
        font-weight: bolder;
        font-size: 1.2em;
        padding: 8px 32px;
        margin-top: 8px;
    }
    // less than sm
    @media (max-width: $breakpoint-sm) {
        button {
            font-size: small;
            padding: 0 8px;
        }
    }
    .validateBtn {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}

.popupStyle {
     :global(.modal-content) {
        padding: 8px 32px;
    }
}

:global(.modal) {
    z-index: 99999 !important;
}

:global(.modal-title) {
    text-align: center;
    padding: 12px;
}

:global(.modal-body .content) {
    text-align: justify;
    span {
        display: contents;
    }
}

:global(.modal-header) {
    padding-bottom: 8px !important;
}