@font-face {
  font-family: 'Comic Sans MS';
  src: url('./comic-sans/comic-sans.woff');
}

@font-face {
  font-family: 'araJozoor-Regular';
  src: url('./araJozoor/araJozoor-Regular.eot');
  src: local('☺'), url('./araJozoor/araJozoor-Regular.woff') format('woff'),
    url('./araJozoor/araJozoor-Regular.ttf') format('truetype'),
    url('./araJozoor/araJozoor-Regular.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}
