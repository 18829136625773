@import 'styles/variables.scss';
.main {
  font-size: 1.4em;
  color: #fc6e99;
  font-weight: bolder;
  :global(.row) {
    padding: 8px;
  }
  :global(.col) {
    align-self: center;
  }
  @media (max-width: $breakpoint-sm) {
    :global(.row) {
      padding: 4px;
    }
  }
}

.questionText {
  font-size: 0.8em;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  @media (max-width: $breakpoint-sm) {
    font-size: 0.65em;
  }
  .questionHtml {
    flex: 80%;
  }
}
