@import 'styles/mixins.scss';

.root {
  background: #fff;
  height: 56px + $spacing * 2;
  flex-shrink: 0;

  @include container();
  @include shadow(1);
}

.innerWrapper {
  display: flex;
  align-items: center;
  composes: flex-row flex-grow-1 justify-content-end from global;
  img {
    width: 56px;
  }
  a {
    margin-left: 16px;
  }
}

.svgContainer {
  & svg {
    height: 36px;
    width: auto;
  }
}

/* less than 767px */
@media (max-width: $breakpoint-md) {
  .svgContainer {
    & svg {
      height: 28px;
      width: auto;
    }
  }
  .innerWrapper {
    img {
      width: 40px;
      height: 32px;
    }
  }
}

.logo {
  padding: 0;
  margin-top: -$spacing * 2;
  composes: svgContainer;
  svg {
    width: auto;
    height: 52px;
  }
}

.desktopMenu {
  padding: 0;
  margin: 0;
  composes: svgContainer;

  & .menuItem {
    list-style: none;

    position: relative;

    :global .nav-link {
      padding: 0;
    }
  }

  & :global {
    a.active:after {
      content: ' ';
      height: 8px;
      width: 100%;
      position: absolute;
      background: $color-primary;
      left: 0;
      border-radius: 14px;
    }

    // NOTE: This is just a quick fix for the
    // react bootstrap router active state
    & a.active[data-rb-event-key='/logout'] {
      &:after {
        display: none;
      }
    }
  }
}
