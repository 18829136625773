@import 'styles/variables.scss';

.playButton {
  cursor: pointer;
  transition: all 0.5s ease 0s;
  svg {
    width: 48px !important;
    height: 48px !important;
  }
  @media (max-width: $breakpoint-sm) {
    svg {
      width: 32px !important;
      height: 32px !important;
    }
  }

  &:not(.checked) .speakerIcon :global(.speaker-wave) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
    transform: scale(1.2);
  }
}

#{$rtl} {
  .playButton {
    transform: rotate(180deg);
  }
}
