@import 'styles/mixins.scss';

:global(body:not(.fullwidth)) {
  :local(.main) {
    flex-grow: 1;
    max-width: 1150px;
    margin: 0 auto;
    @include container();
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}

.surveyBtn {
  position: fixed;
  bottom: 76px;
  right: 16px;
  z-index: 99;
  svg {
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
    width: 64px;
    height: 64px;
  }
  /* less than 767px */
  @media (max-width: 767px) {
    right: -8px;
    svg {
      width: 56px;
      height: 56px;
    }
  }
}
