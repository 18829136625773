@import 'styles/variables.scss';
.colorSvg {
  background-color: none;
  border-radius: 0px;
  width: 68px;
  height: 68px;
  svg {
    width: 72px;
    height: 72px;
  }
  @media (max-width: $breakpoint-sm) {
    width: 48px;
    height: 48px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
}

.happier {
  svg path {
    fill: #00d1b7;
  }
}

.happy {
  svg path {
    fill: #ffcc33;
  }
}

.sad {
  svg path {
    fill: #fc6e99;
  }
}
