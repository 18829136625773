@import "styles/variables.scss";

@mixin container() {
  width: 100%;
  padding-right: $spacing * 2;
  padding-left: $spacing * 2;

  @media (min-width: $breakpoint-md) {
    padding-right: $spacing * 3;
    padding-left: $spacing * 3;
  }
}

/*
  Box shadow mixin, takes 0 to 24 as value
  check $muii-shadows map in styles/variables.scss
*/
@mixin shadow($level) {
  box-shadow: #{map-get($mui-shadows, $level)};
}


// Compares contrast of a given color to the light/dark arguments and returns whichever is most "contrasty"
@function color-contrast($color, $dark: $text, $light: $text-light) {
  @if $color==null {
    @return null;
  }

  @else {
    $yiq: (red($color) * 299 + green($color) * 587 + blue($color) * 114) / 1000;
    @return if($yiq >=128, $dark, $light);
  }
}