@import 'assets/fonts/style.css';
@import 'styles/custom.scss';
@import 'styles/variables.scss';
@import 'styles/rtl.scss';

/*
  giving all elements a box-sizing:border-box
  to have more control over sizes
*/
html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

/* Inherting border-box from html */
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Comic Sans MS', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /*
    this will align text to the start of the direction
    i.e. to the right if rtl or to the left if ltr
  */
  text-align: start;
  color: #555;
}

body[dir='ltr'] {
  direction: ltr;
  unicode-bidi: embed;
}

body[dir='rtl'] {
  font-family: 'araJozoor-Regular', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  direction: rtl;
  unicode-bidi: embed;
}

bdo[dir='ltr'] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

bdo[dir='rtl'] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: $color-primary;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

button,
input[type='text'],
input[type='password'] {
  border: none;
  box-shadow: none;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

a:hover {
  text-decoration: none;
}

button {
  background-color: transparent;
}

.shadow-tb {
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
}
