@import 'styles/variables.scss';

.svgButton {
  background-color: $text--disabled !important;
  border-radius: 16px !important;
  margin: 8px;
  padding: 12px !important;
  min-width: 180px;
  min-height: 160px;
  svg {
    width: 88px;
    height: 88px;
  }
  span {
    color: white;
    font-size: 1.1em;
    font-weight: bolder;
  }
  @media (max-width: $breakpoint-sm) {
    margin: 2px;
    padding: 0 4px !important;
    min-width: 76px !important;
    min-height: 88px;
    svg {
      padding-top: 4px;
      width: 36px;
      height: 36px;
    }
    span {
      font-size: 0.8em;
      font-weight: bolder;
    }
  }
}
.selected {
  background-color: $color-primary !important;
}

button {
  min-width: fit-content !important;
}
