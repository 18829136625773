@import 'styles/variables.scss';
.main {
  font-size: 1.4em;
  color: #fc6e99;
  font-weight: bolder;
  :global(.row) {
    padding: 16px;
  }
  :global(.col) {
    align-self: center;
  }
}

@media (max-width: $breakpoint-sm) {
  .main {
    :global(.row) {
      padding: 8px 0px;
    }
  }

  .questionTitle {
    font-size: 0.7em;
  }
}

:global(.modal-body) {
  padding: 0 !important;
}
