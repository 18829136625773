@import 'styles/variables.scss';

.title {
  margin: 1rem auto 0;
  background-color: $color-tertiary;
  border-radius: 32px;
  padding: 8px 32px;
  color: white;
  font-weight: bold !important;
  font-size: 0.8em !important;
  /* more than sm ( tablet and desktop ) */
  @media (min-width: $breakpoint-sm) {
    font-size: 1.2em !important;
  }
  /* more than lg ( desktop )  */
  @media (min-width: $breakpoint-lg) {
    font-size: 1.4em !important;
  }
}

// .background {
//   background-image: url(~assets/svg/popup.svg);
//   background-size: contain;
//   background-position: center;
//   background-repeat: no-repeat;
// }

.modal {
  :global(.modal-content) {
    border-radius: 48px;
  }
}
