@import 'styles/mixins.scss';

.root {
  @include container();
  min-height: 65px;
  composes: d-flex flex-column justify-content-center from global;
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  bottom: 0;
  z-index: 9999;
  &,
  & a {
    color: #fff;
  }
}

.background {
  width: 250%;
  height: calc(100% + 56px);
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 7;
  background-image: url(~assets/svg/footer.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  composes: position-absolute from global;
}

.footerContents {
  margin-top: 24px;
  z-index: 8;
}
